import './smartphone-teaser.scss';

import Rellax from 'rellax';

class SmartphoneTeaser {
    constructor (element) {
        this.$smartphoneTeaser = element;
        this.$backgroundImage = this.$smartphoneTeaser.querySelectorAll('[data-smartphone-teaser="background"]');
        this.$smartphoneImage = this.$smartphoneTeaser.querySelector('[data-smartphone-teaser="smartphone"]');
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.setParallax();
    }

    setParallax () {
        if (this.$backgroundImage.length > 0) {
            for (let i = 0; i < this.$backgroundImage.length; i++) {
                new Rellax(this.$backgroundImage[i], { // eslint-disable-line
                    speed: 2,
                    center: true,
                    wrapper: null,
                    vertical: true,
                    centering: true
                });
            }
        }

        if (this.$smartphoneImage) {
            new Rellax(this.$smartphoneImage, { // eslint-disable-line
                center: true,
                wrapper: null,
                vertical: true,
                centering: true
            });
        }
    }
}

export { SmartphoneTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $smartphoneTeaserContainer = $context.querySelectorAll('[data-smartphone-teaser="root"]');
        for (let i = 0; i < $smartphoneTeaserContainer.length; i++) {
            const $smartphoneTeaser = new SmartphoneTeaser($smartphoneTeaserContainer[i]);
            $smartphoneTeaser.initialize();
        }
    }
});
